import Vue from "vue";
import VueRouter from "vue-router";
import DashboardPlugin from "./material-dashboard";

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

// import './assets/scss/index.scss';
import VueCookies from 'vue-cookies'

// Plugins
import App from "./App.vue";
import Chartist from "chartist";

// router setup
import routes from "./routes/routes";
// plugin setup
Vue.use(ElementUI);
Vue.use(VueRouter);
Vue.use(DashboardPlugin);
Vue.use(VueCookies);

// 引用调用AIP方法
import { http, httpDownload, getHttpImg, getHttp, httpJson, httpFrom } from './api/index'

// 原型链
Vue.$http = Vue.prototype.$http = http
Vue.$getHttp = Vue.prototype.$getHttp = getHttp
Vue.$getHttpImg = Vue.prototype.$getHttpImg = getHttpImg
Vue.$httpDownload = Vue.prototype.$httpDownload = httpDownload
Vue.$httpFrom = Vue.prototype.$httpFrom = httpFrom
Vue.$httpJson = Vue.prototype.$httpJson = httpJson

// configure router
const router = new VueRouter({
  routes, // short for routes: routes
  scrollBehavior: (to) => {
    if (to.hash) {
      return { selector: to.hash };
    } else {
      return { x: 0, y: 0 };
    }
  },
  linkExactActiveClass: "nav-item active",
});

// global library setup
Vue.prototype.$Chartist = Chartist;

/* eslint-disable no-new */
new Vue({
  el: "#app",
  render: (h) => h(App),
  router,
});
