<template>
  <div class="md-layout text-center">
    <div class="md-layout-item md-size-50 md-small-size-100">
      <lock-card>
        <img class="img" slot="imageProfile" :src="image" />
        <h4 slot="title" class="title">Tania Andrew</h4>
        <md-field slot="content">
          <label>User Name</label>
          <md-input v-model="username" type="text"></md-input>
        </md-field>
        <md-field slot="content">
          <label>New Password</label>
          <md-input autocomplete="new-password" v-model="NewPassword" type="password"></md-input>
        </md-field>
        <md-field slot="content">
          <label>Confirm New Password</label>
          <md-input autocomplete="new-password" v-model="ConfirmNewPassword" type="password"></md-input>
        </md-field>
        <md-button
          href
          class=" md-button md-primary"
          @click="SubmitFn"
          slot="footer"
          >Submit</md-button
        >
      </lock-card>
    </div>
    <notifications></notifications>
  </div>
</template>
<script>
import { LockCard } from "@/components";
import Swal from "sweetalert2";
export default {
  components: {
    LockCard,
  },
  data() {
    return {
      NewPassword:undefined,
      ConfirmNewPassword:undefined,
      username: undefined,
      image: process.env.BASE_URL + "img/icononly_transparent_nobuffer.png",
      token:undefined
    };
  },
  mounted() {
    if (this.$route.query.token) {
      this.token = this.$route.query.token
    }
  },
  methods: {
    SubmitFn() {
      var _this = this;
      if (!_this.username) {
        _this.$notify({
          timeout: 3000,
          message: "username",
          horizontalAlign: "center",
          verticalAlign: "top",
          type: "warning",
        });
        return false;
      }
      if (!this.NewPassword) {
        this.$notify({
          timeout: 3000,
          message: "Please enter a new password !",
          horizontalAlign: "center",
          verticalAlign: "top",
          type: "warning",
        });
        return false;
      } else if (this.NewPassword.length < 8) {
        this.$notify({
          timeout: 3000,
          message: "New Password At least 8 characters !",
          horizontalAlign: "center",
          verticalAlign: "top",
          type: "warning",
        });
        return false;
      }
      if (!this.ConfirmNewPassword) {
        this.$notify({
          timeout: 3000,
          message: "Please enter a duplicate new password !",
          horizontalAlign: "center",
          verticalAlign: "top",
          type: "warning",
        });
        return false;
      } else if (this.ConfirmNewPassword.length < 8) {
        this.$notify({
          timeout: 3000,
          message: "Confirm New Password At least 8 characters !",
          horizontalAlign: "center",
          verticalAlign: "top",
          type: "warning",
        });
        return false;
      }
      if (
        this.NewPassword !=
        this.ConfirmNewPassword
      ) {
        this.$notify({
          timeout: 3000,
          message: "The two passwords are inconsistent !",
          horizontalAlign: "center",
          verticalAlign: "top",
          type: "warning",
        });
        return false;
      }
      var reg =
        /^(?=.*?[a-z])(?=.*?\d)(?=.*?[~!@#$%^&*])[a-zA-Z\d!#@*&.]{8,}$/;
      if (!reg.test(this.NewPassword)) {
        this.$notify({
          timeout: 3000,
          message: "Passwords must include lowercase, uppercase, numbers, and symbols !",
          horizontalAlign: "center",
          verticalAlign: "top",
          type: "warning",
        });
        return false;
      }
      this.$httpFrom("/ToWork/Authentication/resetpassword", {
        username: this.username,
        token: this.token,
        new_password: this.NewPassword,
      }).then((ret) => {
        if (ret.data.success) {
          _this.$notify({
            timeout: 3000,
            message: ret.data.message ? ret.data.message : ret.data.result,
            horizontalAlign: "center",
            verticalAlign: "top",
            type: "success",
          });
          _this.$router.push({ path: '/login' });
        } else {
          _this.$notify({
            timeout: 3000,
            message: ret.data.message ? ret.data.message : ret.data.result,
            horizontalAlign: "center",
            verticalAlign: "top",
            type: "danger",
          });
        }
      });
    },
  },
};
</script>
<style></style>
