<template>
  <div class="md-layout">
    <div class="md-layout-item">
      <signup-card>
        <div class="md-layout-item md-size-100 md-medium-size-100 md-small-size-100 ml-auto " slot="content-header">
          <div style="max-width:800px; margin:0px auto">
            <h4 class="info-title" style="font-weight:bold;text-align:center;font-size: 24px;">No Costs, No Cards, No Hassles Get started immediately with up to 3 bidding projects during our beta phase.</h4>
          </div>
        </div>
        <div class="md-layout-item md-size-100 md-medium-size-100 md-small-size-100 ml-auto" slot="content-left">
          <!-- <div class="info info-horizontal">
            <div class="icon icon-success">
              <md-icon>timeline</md-icon>
            </div>
            <div class="description">
              <h4 class="info-title">Exclusive Beta Trial Benefits</h4>
              <p class="description">Full Bidding Module Access: Get to grips with our robust bidding module and discover the potential it holds for your business.</p>
              <p class="description">Learning Resources: Enjoy our rich library of guided content, detailed how-tos, and industry best practices, especially for bidding.</p>
              <p class="description">Customized Onboarding: Benefit from a tailored online training experience to kickstart your WorkWizard journey.</p>
            </div>
          </div> -->
          <div style="border-radius: 5px;background-color: #f5f5f5; padding: 10px 0;">
            <div class="info info-horizontal" style="max-width:800px; margin:0px auto">
              <div class="icon icon-info" style="margin-right:15px;">
                <img src="../../../assets/img/icon_1.png" />
                <!-- <md-icon>code</md-icon> -->
              </div>
              <div class="description">
                <h4 class="info-title">We Value Your Input</h4>
                <p class="description">As a valued member of our beta community, your
  insights will guide the evolution of WorkWizard.</p>
              </div>
            </div>
          
            <div class="info info-horizontal" style="max-width:800px; margin:0px auto">
              <div class="icon icon-success" style="margin-right:15px;">
                <!-- <md-icon>timeline</md-icon> -->
                <img src="../../../assets/img/icon_2.png" />
              </div>
              <div class="description">
                <h4 class="info-title">StartedBe a Part of Something Transformative.</h4>
                <p class="description">To tailor your beta trial, we need some details. Your company website</p>
              </div>
            </div>
          </div>
        </div>
        <div class="md-layout-item md-size-50 md-medium-size-50 md-small-size-100 mr-auto" style="margin-top:15px;" slot="content-right">
          <div class="info info-horizontal">
            <!-- <ValidationProvider name="website" rules="required" v-slot="{ passed, failed }">
              <md-field class="md-form-group" :class="[{ 'md-error': failed }, { 'md-valid': passed }]">
                <md-icon>language</md-icon>
                <label>Company Website...</label>
                <md-input v-model="RegisterInfo.website" type="text"></md-input>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="failed">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="passed">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </ValidationProvider> -->
            <md-field class="md-form-group">
              <md-icon>language</md-icon>
              <label>Company Website...</label>
              <md-input v-model="RegisterInfo.website" type="text"></md-input>
            </md-field>
            <ValidationProvider name="firstname" rules="required" v-slot="{ passed, failed }">
              <md-field class="md-form-group" :class="[{ 'md-error': failed }, { 'md-valid': passed }]">
                <md-icon>face</md-icon>
                <label>First Name...</label>
                <md-input v-model="RegisterInfo.firstname" type="text"></md-input>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="failed">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="passed">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </ValidationProvider>
            <ValidationProvider name="lastname" rules="required" v-slot="{ passed, failed }">
              <md-field class="md-form-group" :class="[{ 'md-error': failed }, { 'md-valid': passed }]">
                <md-icon>record_voice_over</md-icon>
                <label>Last Name...</label>
                <md-input v-model="RegisterInfo.lastname" type="text"></md-input>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="failed">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="passed">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </ValidationProvider>
            <ValidationProvider name="password" rules="numeric" v-slot="{ passed, failed }">
              <md-field class="md-form-group" :class="[{ 'md-error': failed }, { 'md-valid': passed }]">
                <md-icon>phone</md-icon>
                <label>Phone...</label>
                <md-input v-model="RegisterInfo.phone" type="text"></md-input>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="failed">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="passed">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </ValidationProvider>
          </div>
        </div>
        <div class="md-layout-item md-size-50 md-medium-size-50 md-small-size-100 mr-auto" style="margin-top:15px;" slot="content-right">
          <div class="info info-horizontal">
            <ValidationProvider name="email" rules="required|email" v-slot="{ passed, failed }">
              <md-field class="md-form-group" :class="[{ 'md-error': failed }, { 'md-valid': passed }]">
                <md-icon>email</md-icon>
                <label>Work Email...</label>
                <md-input v-model="RegisterInfo.email" type="email"></md-input>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="failed">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="passed">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </ValidationProvider>
            

            <ValidationProvider name="password" rules="required" v-slot="{ passed, failed }">
              <md-field class="md-form-group" :class="[{ 'md-error': failed }, { 'md-valid': passed }]">
                <md-icon>lock_outline</md-icon>
                <label>Password...</label>
                <md-input autocomplete="new-password" v-model="RegisterInfo.password" type="password"></md-input>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="failed">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="passed">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </ValidationProvider>
            <ValidationProvider name="password_confirm" rules="required" v-slot="{ passed, failed }">
              <md-field class="md-form-group" :class="[{ 'md-error': failed }, { 'md-valid': passed }]">
                <md-icon>lock_outline</md-icon>
                <label>Password Confirm...</label>
                <md-input autocomplete="new-password" v-model="RegisterInfo.password_confirm" type="password"></md-input>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="failed">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="passed">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </ValidationProvider>
          </div>
        </div>
        <div class="md-layout-item md-size-100 md-medium-size-100 md-small-size-100 ml-auto " slot="content-footer">
          <div style="max-width:800px; margin:0px auto;">
            <md-checkbox v-model="boolean">I agree to the <a>Main Services Agreement</a>.</md-checkbox>
          </div>
          <div style="max-width:800px; margin:0px auto; text-align: center;">
            <div style="font-size: 1.125rem;line-height: 1.4em;font-weight: 400;margin-top: 1.5rem !important;">By signing up, l agree to contribute to the development of WorkWizard and consent to process my personal data as
outlined in the <a href="#">Privacy Statement.</a></div>
            <md-button @click="SubmitFn" class=" md-button md-primary mt-4" slot="footer">Begin Your Beta Trial</md-button>
          </div>
        </div>
      </signup-card>
    </div>
    <notifications></notifications>
  </div>
</template>
<script>
import { SlideYDownTransition } from "vue2-transitions";
import { extend } from "vee-validate";
import {
  required,
  numeric,
  regex,
  confirmed,
  email,
} from "vee-validate/dist/rules";

extend("required", required);
extend("numeric", numeric);
extend("email", email);
extend("regex", regex);
extend("confirmed", confirmed);
import { SignupCard } from "@/components";
export default {
  components: {
    SignupCard,
    SlideYDownTransition,
  },
  data() {
    return {
      RegisterInfo: {},
      firstname: null,
      boolean: true,
      email: null,
      password: null
    };
  },
  created(){
    document.title = "Work Wizard Beta Free Trial";
    // var link = document.querySelector("link[rel*='icon']")
    // link.href=res.msg
  },
  mounted(){
    document.title = "Work Wizard Beta Free Trial";
    // var link = document.querySelector("link[rel*='icon']")
    // link.href=res.msg
  },
  methods: {
    IsURL(str_url){
      var strRegex = "^((https|http|ftp|rtsp|mms)?://)" 
      + "?(([0-9a-z_!~*'().&=+$%-]+: )?[0-9a-z_!~*'().&=+$%-]+@)?" //ftp的user@ 
        + "(([0-9]{1,3}.){3}[0-9]{1,3}" // IP形式的URL- 199.194.52.184 
        + "|" // 允许IP和DOMAIN（域名）
        + "([0-9a-z_!~*'()-]+.)*" // 域名- www. 
        + "([0-9a-z][0-9a-z-]{0,61})?[0-9a-z]." // 二级域名 
        + "[a-z]{2,6})" // first level domain- .com or .museum 
        + "(:[0-9]{1,4})?" // 端口- :80 
        + "((/?)|" // a slash isn't required if there is no file name 
        + "(/[0-9a-z_!~*'().;?:@&=+$,%#-]+)+/?)$"; 
        var re=new RegExp(strRegex); 
      //re.test()
        if (re.test(str_url)){
        return (true); 
        }else{ 
        return (false); 
        }
      },
    SubmitFn() {
      var _this = this;
      if(_this.RegisterInfo.website && !_this.IsURL(_this.RegisterInfo.website)){
        _this.$notify({
          timeout: 3000,
          message: "Please enter the company website !",
          horizontalAlign: "center",
          verticalAlign: "top",
          type: "warning",
        });
        return false;
      }
      if (!this.RegisterInfo.password) {
        this.$notify({
          timeout: 3000,
          message: "Please enter a new password !",
          horizontalAlign: "center",
          verticalAlign: "top",
          type: "warning",
        });
        return false;
      } else if (this.RegisterInfo.password.length < 8) {
        this.$notify({
          timeout: 3000,
          message: "New Password At least 8 characters !",
          horizontalAlign: "center",
          verticalAlign: "top",
          type: "warning",
        });
        return false;
      }
      if (!this.RegisterInfo.password_confirm) {
        this.$notify({
          timeout: 3000,
          message: "Please enter a duplicate new password !",
          horizontalAlign: "center",
          verticalAlign: "top",
          type: "warning",
        });
        return false;
      } else if (this.RegisterInfo.password_confirm.length < 8) {
        this.$notify({
          timeout: 3000,
          message: "Confirm New Password At least 8 characters !",
          horizontalAlign: "center",
          verticalAlign: "top",
          type: "warning",
        });
        return false;
      }
      if (
        this.RegisterInfo.password !=
        this.RegisterInfo.password_confirm
      ) {
        this.$notify({
          timeout: 3000,
          message: "The two passwords are inconsistent !",
          horizontalAlign: "center",
          verticalAlign: "top",
          type: "warning",
        });
        return false;
      }
      var reg =
        /^(?=.*?[a-z])(?=.*?\d)(?=.*?[~!@#$%^&*])[a-zA-Z\d!#@*&.]{8,}$/;
      if (!reg.test(this.RegisterInfo.password)) {
        this.$notify({
          timeout: 3000,
          message: "Passwords must include lowercase, uppercase, numbers, and symbols !",
          horizontalAlign: "center",
          verticalAlign: "top",
          type: "warning",
        });
        return false;
      }
      if(!this.boolean){
        this.$notify({
          timeout: 3000,
          message: "Please agree to the main service agreement !",
          horizontalAlign: "center",
          verticalAlign: "top",
          type: "warning",
        });
        return false;
      }
      if (!reg.test(this.RegisterInfo.password_confirm)) {
        this.$notify({
          timeout: 3000,
          message: "Passwords must include lowercase, uppercase, numbers, and symbols !",
          horizontalAlign: "center",
          verticalAlign: "top",
          type: "warning",
        });
        return false;
      }
      this.$httpFrom("/ToWork/Authentication/register", this.RegisterInfo).then((ret) => {
        if (ret.data.success) {
          _this.$notify({
            timeout: 3000,
            message: ret.data.message ? ret.data.message : ret.data.result,
            horizontalAlign: "center",
            verticalAlign: "top",
            type: "success",
          });
          _this.$router.push({ path: '/login' });
        } else {
          _this.$notify({
            timeout: 3000,
            message: ret.data.message ? ret.data.message : ret.data.result,
            horizontalAlign: "center",
            verticalAlign: "top",
            type: "danger",
          });
        }
      });
    },
  },
};
</script>
<style></style>
