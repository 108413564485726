<template>
  <div class="md-layout">
    <div class="md-layout-item">
      <signup-card>
        <h2 class="title text-center" slot="title">Register</h2>
        <div class="md-layout-item md-size-100 md-medium-size-100 md-small-size-100 mr-auto" slot="content-right">
          <ValidationProvider name="email" rules="required|email" v-slot="{ passed, failed }">
            <md-field class="md-form-group" :class="[{ 'md-error': failed }, { 'md-valid': passed }]">
              <md-icon>email</md-icon>
              <label>Email...</label>
              <md-input v-model="RegisterInfo.email" type="email"></md-input>
              <slide-y-down-transition>
                <md-icon class="error" v-show="failed">close</md-icon>
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon class="success" v-show="passed">done</md-icon>
              </slide-y-down-transition>
            </md-field>
          </ValidationProvider>

          <ValidationProvider name="firstname" rules="required" v-slot="{ passed, failed }">
            <md-field class="md-form-group" :class="[{ 'md-error': failed }, { 'md-valid': passed }]">
              <md-icon>face</md-icon>
              <label>First Name...</label>
              <md-input v-model="RegisterInfo.firstname" type="text"></md-input>
              <slide-y-down-transition>
                <md-icon class="error" v-show="failed">close</md-icon>
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon class="success" v-show="passed">done</md-icon>
              </slide-y-down-transition>
            </md-field>
          </ValidationProvider>

          <ValidationProvider name="lastname" rules="required" v-slot="{ passed, failed }">
            <md-field class="md-form-group" :class="[{ 'md-error': failed }, { 'md-valid': passed }]">
              <md-icon>record_voice_over</md-icon>
              <label>Last Name...</label>
              <md-input v-model="RegisterInfo.lastname" type="text"></md-input>
              <slide-y-down-transition>
                <md-icon class="error" v-show="failed">close</md-icon>
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon class="success" v-show="passed">done</md-icon>
              </slide-y-down-transition>
            </md-field>
          </ValidationProvider>
          <md-field class="md-form-group">
            <md-icon>phone</md-icon>
            <label>Telephone...</label>
            <md-input v-model="RegisterInfo.phone" type="text"></md-input>
          </md-field>

          <ValidationProvider name="password" rules="required" v-slot="{ passed, failed }">
            <md-field class="md-form-group" :class="[{ 'md-error': failed }, { 'md-valid': passed }]">
              <md-icon>lock_outline</md-icon>
              <label>Password...</label>
              <md-input autocomplete="new-password" v-model="RegisterInfo.password" type="password"></md-input>
              <slide-y-down-transition>
                <md-icon class="error" v-show="failed">close</md-icon>
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon class="success" v-show="passed">done</md-icon>
              </slide-y-down-transition>
            </md-field>
          </ValidationProvider>
          <ValidationProvider name="password_confirm" rules="required" v-slot="{ passed, failed }">
            <md-field class="md-form-group" :class="[{ 'md-error': failed }, { 'md-valid': passed }]">
              <md-icon>lock_outline</md-icon>
            <label>Password Confirm...</label>
            <md-input autocomplete="new-password" v-model="RegisterInfo.password_confirm" type="password"></md-input>
              <slide-y-down-transition>
                <md-icon class="error" v-show="failed">close</md-icon>
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon class="success" v-show="passed">done</md-icon>
              </slide-y-down-transition>
            </md-field>
          </ValidationProvider>
          <div class="button-container">
            <md-button @click="SubmitFn" class=" md-button md-primary" slot="footer">Get Started</md-button>
          </div>
        </div>
      </signup-card>
    </div>
    <notifications></notifications>
  </div>
</template>
<script>
import { SlideYDownTransition } from "vue2-transitions";
import { extend } from "vee-validate";
import {
  required,
  numeric,
  regex,
  confirmed,
  email,
} from "vee-validate/dist/rules";

extend("required", required);
extend("numeric", numeric);
extend("email", email);
extend("regex", regex);
extend("confirmed", confirmed);
import { SignupCard } from "@/components";
export default {
  components: {
    SignupCard,
    SlideYDownTransition,
  },
  data() {
    return {
      RegisterInfo: {},
      firstname: null,
      boolean: false,
      email: null,
      password: null,
      contentLeft: [
        {
          colorIcon: "icon-success",
          icon: "timeline",
          title: "Marketing",
          description:
            "We've created the marketing campaign of the website. It was a very interesting collaboration.",
        },

        {
          colorIcon: "icon-danger",
          icon: "code",
          title: "Fully Coded in HTML5",
          description:
            "We've developed the website with HTML5 and CSS3. The client has access to the code using GitHub.",
        },

        {
          colorIcon: "icon-info",
          icon: "group",
          title: "Built Audience",
          description:
            "There is also a Fully Customizable CMS Admin Dashboard for this product.",
        },
      ],
    };
  },
  methods: {
    SubmitFn() {
      var _this = this;
      if (!this.RegisterInfo.password) {
        this.$notify({
          timeout: 3000,
          message: "Please enter a new password !",
          horizontalAlign: "center",
          verticalAlign: "top",
          type: "warning",
        });
        return false;
      } else if (this.RegisterInfo.password.length < 8) {
        this.$notify({
          timeout: 3000,
          message: "New Password At least 8 characters !",
          horizontalAlign: "center",
          verticalAlign: "top",
          type: "warning",
        });
        return false;
      }
      if (!this.RegisterInfo.password_confirm) {
        this.$notify({
          timeout: 3000,
          message: "Please enter a duplicate new password !",
          horizontalAlign: "center",
          verticalAlign: "top",
          type: "warning",
        });
        return false;
      } else if (this.RegisterInfo.password_confirm.length < 8) {
        this.$notify({
          timeout: 3000,
          message: "Confirm New Password At least 8 characters !",
          horizontalAlign: "center",
          verticalAlign: "top",
          type: "warning",
        });
        return false;
      }
      if (
        this.RegisterInfo.password !=
        this.RegisterInfo.password_confirm
      ) {
        this.$notify({
          timeout: 3000,
          message: "The two passwords are inconsistent !",
          horizontalAlign: "center",
          verticalAlign: "top",
          type: "warning",
        });
        return false;
      }
      var reg = /^(?=.*?[a-z])(?=.*?\d)(?=.*?[~!@#$%^&*])[a-zA-Z\d!#@*&.]{8,}$/;
      if (!reg.test(this.RegisterInfo.password)) {
        this.$notify({
          timeout: 3000,
          message: "Passwords must include lowercase, uppercase, numbers, and symbols !",
          horizontalAlign: "center",
          verticalAlign: "top",
          type: "warning",
        });
        return false;
      }
      this.$httpFrom("/ToWork/Authentication/register", this.RegisterInfo).then((ret) => {
        if (ret.data.success) {
          _this.$notify({
            timeout: 3000,
            message: ret.data.message ? ret.data.message : ret.data.result,
            horizontalAlign: "center",
            verticalAlign: "top",
            type: "success",
          });
          _this.$router.push({ path: '/login' });
        } else {
          _this.$notify({
            timeout: 3000,
            message: ret.data.message ? ret.data.message : ret.data.result,
            horizontalAlign: "center",
            verticalAlign: "top",
            type: "danger",
          });
        }
      });
    },
  },
};
</script>
<style></style>
