
import AuthLayout from "@/pages/main/Assembly/AuthLayout.vue";
import NewLogin from "@/pages/main/LoginRegister/Login.vue";
import NewRegister from "@/pages/main/LoginRegister/Register.vue";
import RetrievePassword from "@/pages/main/LoginRegister/RetrievePassword.vue";
import ResetPassword from "@/pages/main/LoginRegister/ResetPassword.vue";
import FreeTrail from "@/pages/main/LoginRegister/FreeTrail.vue";

var windowHref = window.location.href
if(windowHref.indexOf('?returnurl=')!=-1){
  localStorage.setItem('returnurl',windowHref.substring(windowHref.indexOf('?returnurl=')+11,windowHref.length))
}else{
  localStorage.removeItem('returnurl')
}

const routes = [
  // 添加404页面路由
  {
    path: '*',
    component: AuthLayout,
    name: "Login",
    redirect: "/login",
    children: [{
      path: "/login",
      name: "Login",
      component: NewLogin,
    }]
  },
  {
    path: "/",
    component: AuthLayout,
    name: "Login",
    redirect: "/login",
    children: [
      {
        path: "/login",
        name: "Login",
        component: NewLogin,
      },
      {
        path: "/register",
        name: "Register",
        component: NewRegister,
      },
      {
        path: "/freetrail",
        name: "FreeTrail",
        component: FreeTrail,
      },
      {
        path: "/retrievePassword",
        name: "Retrieve Password",
        component: RetrievePassword,
      },
      {
        path: "/resetPassword",
        name: "Reset Password",
        component: ResetPassword,
      }
    ]
  }
];

export default routes;
