import axios from 'axios'
import qs from 'qs'
axios.defaults.baseURL = '/API'
axios.defaults.timeout = 60000

var itn = 0;
function catchError (error) {
  console.log(error)
  if (error.response.status === 401) {
    itn++;
    if(itn===1){
      alert(error.response.data.Message)
      window.location.href='/Login';
      itn=-2
    }
  }
  if (error.response.status === 500) {
    alert(error.response.data.result)
  }        
  return Promise.reject(error)
}

function http(url, params, enforce) {
  let ajaxUrl
  var apiDate
  if (url.indexOf(':') !== -1) {
    let urlParamLength = url.split(':').length - 1
    if (params && params.length === urlParamLength) {
      ajaxUrl = url.substring(0, url.indexOf(':')) + params.join('/')
    } else {
      console.error('参数个数与api参数个数不一致')
    }
  } else {
    ajaxUrl = url
  }

  if (Object.prototype.toString.call(params) === '[object Object]') {
    apiDate = axios.post(ajaxUrl, qs.stringify(params) ).then(data => {
      return data
    }).catch(error => {
      return catchError(error)     
    })
  } else {
    apiDate = axios.post(ajaxUrl, qs.stringify(params), {
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'Content-Type': 'application/json; charset=utf-8',
      }
    }).then(data => {
      return data
    }).catch(error => {
      return catchError(error)
    })
  }
  return apiDate
}

function httpFrom(url, params, enforce) {
  let ajaxUrl
  var apiDate
  if (url.indexOf(':') !== -1) {
    let urlParamLength = url.split(':').length - 1
    if (params && params.length === urlParamLength) {
      ajaxUrl = url.substring(0, url.indexOf(':')) + params.join('/')
    } else {
      console.error('参数个数与api参数个数不一致')
    }
  } else {
    ajaxUrl = url
  }

  if (Object.prototype.toString.call(params) === '[object Object]') {
    apiDate = axios.post(ajaxUrl, qs.stringify(params) ).then(data => {
      return data
    }).catch(error => {
      return catchError(error)     
    })
  } else {
    apiDate = axios.post(ajaxUrl, qs.stringify(params), {
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'Content-Type': 'application/json; charset=utf-8',
      }
    }).then(data => {
      return data
    }).catch(error => {
      return catchError(error)
    })
  }
  return apiDate
}

function httpDownload(url, params, enforce) {
  var request = axios.create({
    baseURL: '/API/',

  });
  let ajaxUrl
  var apiDate
  if (url.indexOf(':') !== -1) {
    let urlParamLength = url.split(':').length - 1
    if (params && params.length === urlParamLength) {
      ajaxUrl = url.substring(0, url.indexOf(':')) + params.join('/')
    } else {
      console.error('参数个数与api参数个数不一致')
    }
  } else {
    ajaxUrl = url
  }

  if (Object.prototype.toString.call(params) === '[object Object]') {
    apiDate = axios.post(ajaxUrl, qs.stringify(params), {
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'Content-Type': 'application/x-www-form-urlencoded',
        'Accept': 'application/json'
      },
      responseType: 'arraybuffer'
    }).then(data => {
      return data
    }).catch(error => {
      return catchError(error)
    })
  } else {
    apiDate = axios.post(ajaxUrl, qs.stringify(params), {
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'Content-Type': 'application/x-www-form-urlencoded',
        'Accept': 'application/json'
      },
      responseType: 'arraybuffer'
    }).then(data => {
      return data
    }).catch(error => {
      return catchError(error)
    })
  }
  return apiDate
}

function httpJson(url, params, enforce) {
  let ajaxUrl
  var apiDate
  if (url.indexOf(':') !== -1) {
    let urlParamLength = url.split(':').length - 1
    if (params && params.length === urlParamLength) {
      ajaxUrl = url.substring(0, url.indexOf(':')) + params.join('/')
    } else {
      console.error('参数个数与api参数个数不一致')
    }
  } else {
    ajaxUrl = url
  }

  if (Object.prototype.toString.call(params) === '[object Object]') {
    apiDate = axios.post(ajaxUrl, params, {
      headers: {
        'Content-Type': 'application/json; charset=utf-8'
      }
    }).then(data => {
      return data
    }).catch(error => {
      return catchError(error)
    })
  } else {
    apiDate = axios.post(ajaxUrl, params, {
      headers: {
        'Content-Type': 'application/json; charset=utf-8'
      }
    }).then(data => {
      return data
    }).catch(error => {      
      return catchError(error)
    })
  }
  return apiDate
}

function getHttp(url, params, enforce) {
  let ajaxUrl
  var apiDate
  if (url.indexOf(':') !== -1) {
    let urlParamLength = url.split(':').length - 1
    if (params && params.length === urlParamLength) {
      ajaxUrl = url.substring(0, url.indexOf(':')) + params.join('/')
    } else {
      console.error('参数个数与api参数个数不一致')
    }
  } else {
    ajaxUrl = url
  }

  if (Object.prototype.toString.call(params) === '[object Object]') {
    apiDate = axios.get(ajaxUrl, { params: { ...params } }).then(data => {
      return data
    }).catch(error => {
      return catchError(error)
    })
  } else {
    apiDate = axios.get(ajaxUrl, params, {
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'Content-Type': 'application/json'
      }
    }).then(data => {
      return data
    }).catch(error => {
      return catchError(error)
    })
  }
  return apiDate
}

function getHttpImg(url, params, enforce) {
  let ajaxUrl
  var apiDate
  if (url.indexOf(':') !== -1) {
    let urlParamLength = url.split(':').length - 1
    if (params && params.length === urlParamLength) {
      ajaxUrl = url.substring(0, url.indexOf(':')) + params.join('/')
    } else {
      console.error('参数个数与api参数个数不一致')
    }
  } else {
    ajaxUrl = url
  }
  if (enforce) {
    this.$moduleConfig && this.$moduleConfig.children && this.$moduleConfig.children.map(r => {
      if (r.name === enforce) {
        if(r.tabId!=0){
          axios.defaults.headers.post['ModuleId'] = r.moduleId
          axios.defaults.headers.post['TabId'] = r.tabId
        }
      }
    })
  } else {
    if(this.$moduleConfig.tabId!=0){
      axios.defaults.headers.post['ModuleId'] = this.$moduleConfig.moduleId
      axios.defaults.headers.post['TabId'] = this.$moduleConfig.tabId
    }
  }
  apiDate = axios.get(ajaxUrl, params).then(data => {
    return data
  }).catch(error => {
    return catchError(error)
  })
  return apiDate
}


function showError (ret) {
  var info = 'error'
  if (ret.data.message) {
    info = ret.data.message
  } else if (ret.data.result) {
    info = ret.data.result
  }
  alert(info)
}

function showWarning (message) {
  this.$notify({
    timeout: 3000,
    message: message,
    horizontalAlign: "center",
    verticalAlign: 'top',
    type: 'danger'
  })
}

function showSuccess (ret) {
  if (ret == null) {
    alert('Success')
  } else {
    if (ret.data.message) {
      alert(ret.data.message)
    } else if (ret.data.result) {
      alert(ret.data.result)
    } else {
      alert('Success')
    }
  }
}

export { http, httpDownload, getHttpImg, getHttp, httpFrom, httpJson, showError, axios, showWarning, showSuccess }
