<template>
  <div class="md-layout text-center">
    <div class="md-layout-item md-size-50 md-small-size-100">
      <lock-card>
        <img class="img" slot="imageProfile" :src="image" />
        <h4 slot="title" class="title">Retrieve Password</h4>
        <md-field slot="content">
          <label>Email...</label>
          <md-input v-model="username" type="text"></md-input>
        </md-field>
        <md-button href class=" md-button md-primary" @click="UnlockFn" slot="footer">Unlock</md-button
        >
      </lock-card>
    </div>
    <notifications></notifications>
  </div>
</template>
<script>
import { LockCard } from "@/components";
import Swal from "sweetalert2";

export default {
  components: {
    LockCard,
  },
  data() {
    return {
      username: null,
      image: process.env.BASE_URL+"img/icononly_transparent_nobuffer.png",
    };
  },
  methods: {
    UnlockFn(){
      var _this = this;
      if(!_this.username){
        _this.$notify({
          timeout: 3000,
          message: "Email...",
          horizontalAlign: "center",
          verticalAlign: "top",
          type: "warning"
        });
        return false
      }
      this.$httpFrom('/ToWork/Authentication/sendpassword', { username: this.username }).then(ret => {
        if (ret.data.success) {
          Swal.fire({
            title: `Success !`,
            buttonsStyling: false,
            confirmButtonClass: "md-button md-success",
          })
        } else {
          _this.$notify({
            timeout: 3000,
            message: ret.data.message ? ret.data.message : ret.data.result,
            horizontalAlign: "center",
            verticalAlign: 'top',
            type: 'danger'
          })
        }
      })
    }
  }
};
</script>
<style></style>
