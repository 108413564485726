<template>
  <div class="md-layout text-center">
    <div class="md-layout-item md-size-33 md-medium-size-50 md-small-size-70 md-xsmall-size-100">
      <login-card header-color="purple">
        <div slot="logoimg" class="logoimg">
          <div class="logo_img"><img :src="logo" /></div>
          <div class="logo_font">WORK WIZARD</div>
        </div>
        <md-field class="md-form-group" slot="inputs">
          <md-icon>face</md-icon>
          <label>Email...</label>
          <md-input @keyup.enter="loginFn" v-model="username"></md-input>
        </md-field>
        <md-field class="md-form-group" slot="inputs">
          <md-icon>lock_outline</md-icon>
          <label>Password...</label>
          <md-input autocomplete="new-password" @keyup.enter="loginFn" type="password" v-model="password"></md-input>
        </md-field>
        <md-button @click="loginFn" slot="footer" class="md-simple md-dribbble md-lg md-theme-default">
          Lets Go
        </md-button>
      </login-card>
    </div>
    <notifications></notifications>
  </div>
</template>
<script>

import { LoginCard } from "@/components";

export default {
  components: {
    LoginCard,
  },
  data() {
    return {
      loading:false,
      username: undefined,
      password: undefined,
      logo: process.env.BASE_URL + "img/icononly_transparent_nobuffer.png"
    };
  },
  methods: {
    loginFn() {
      var _this = this;
      if (!_this.username) {
        _this.$notify({
          timeout: 3000,
          message: "username",
          horizontalAlign: "center",
          verticalAlign: "top",
          type: "warning"
        });
        return false
      }
      if (!_this.password) {
        _this.$notify({
          timeout: 3000,
          message: "password",
          horizontalAlign: "center",
          verticalAlign: "top",
          type: "warning"
        });
        return false
      }
      // var reg = /^(?=.*?[a-z])(?=.*?\d)(?=.*?[~!@#$%^&*])[a-zA-Z\d!#@*&.]{8,}$/;
      // if (!reg.test(this.password)) {
      //   this.$notify({
      //     timeout: 3000,
      //     message: "Passwords must include lowercase, uppercase, numbers, and symbols !",
      //     horizontalAlign: "center",
      //     verticalAlign: "top",
      //     type: "warning",
      //   });
      //   return false;
      // }
      // this.loading = true;
      this.$emit("loadingInfo",true)
      this.$httpFrom('/ToWork/Authentication/login', { username: this.username, password: this.password }).then(ret => {
        // this.loading = false;
        this.$emit("loadingInfo",false)
        if (ret.data.success) {
          if(localStorage.getItem('returnurl')){
            var loginUrl = localStorage.getItem('returnurl').indexOf("/login")
            var FreeTrailUrl = localStorage.getItem('returnurl').indexOf("/FreeTrail")
            var RegisterUrl = localStorage.getItem('returnurl').indexOf("/Register")
            var ResetPasswordUrl = localStorage.getItem('returnurl').indexOf("/ResetPassword")
            var RetrievePasswordUrl = localStorage.getItem('returnurl').indexOf("/RetrievePassword")
            if(loginUrl!=-1||FreeTrailUrl!=-1||RegisterUrl!=-1||ResetPasswordUrl!=-1||RetrievePasswordUrl!=-1){
              window.location.href = "/"
            }else{
              window.location.href = localStorage.getItem('returnurl')
            }
          }else{
            window.location.href = "/"
          }
          
        } else {
          _this.$notify({
            timeout: 3000,
            message: ret.data.message ? ret.data.message : ret.data.result,
            horizontalAlign: "center",
            verticalAlign: 'top',
            type: 'danger'
          })
        }
      })
    }
  }
};
</script>

<style>
.logoimg {
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
}

.logoimg .logo_img {
  width: 20%;
  background: #ffffff;
  border-radius: 50%;
}

.logoimg .logo_font {
  font-size: 24px;
  color: #ffffff;
  margin-left: 10px;
}
</style>
